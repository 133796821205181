import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import { Home } from "./Pages/Home/Home";
import { Product } from "./Pages/Product/Product";

function App() {
  return (
    <div className="App">
      <Router>
        <header className="App-header">
          <Link to="/">
            <img src={logo} className="App-logo" alt="logo" />
          </Link>
        </header>
        <div className="App-main">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/products/:productid">
              <Product />
            </Route>
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;
