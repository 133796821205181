import { FormProps, UiSchema } from "@rjsf/core";
import { JSONSchema7 } from "json-schema";
import { getJsonSchemaDate } from "../../utils";

export interface IProduct {
  id: string;
  name: string;
  orderSchema: JSONSchema7;
  uiSchema?: UiSchema;
  patternErrorMessages?: Record<string, string>;
  validate?: FormProps<any>["validate"];
}

export const products: Record<string, IProduct> = {
  breast_pump: {
    id: "breast_pump",
    name: "Breast Pump",
    orderSchema: {
      type: "object",
      properties: {
        useage: {
          type: "object",
          title: "Specify a duration",
          description:
            "The fields below will help use select the correct order form for you.",
          properties: {
            frequency: {
              type: "string",
              title: "Frequency of use",
              enum: ["Hourly", "Nightly", "Weekly"],
            },
            duration: {
              type: "number",
              title: "Length of need (months)",
            },
          },
        },
        patientInformation: {
          title: "Patient Characteristics",
          type: "object",
          properties: {
            weight: {
              type: "number",
              title: "Weight (lbs.)",
            },
            height: {
              type: "string",
              title: "Height",
              pattern: `^[0-9]'\\s?(?:[0-9]"|1[01]")?$`,
            },
          },
        },
        infantDOB: {
          title: "Infant Date of Birth",
          type: "string",
          format: "date",
          default: getJsonSchemaDate(new Date()),
        },
        patientDiagnosis: {
          type: "string",
          title: "Patient Diagnosis",
          anyOf: [
            {
              type: "string",
              title: "Pregnant mother (Z33.1)",
              enum: ["Z33.1"],
            },
            {
              type: "string",
              title: "Suppressed Lactation (029.5)",
              enum: ["029.5"],
            },
            {
              type: "string",
              title: "Engorgement, postpartum (092.29)",
              enum: ["092.29"],
            },
          ],
        },
      },
    },
    uiSchema: {
      patientInformation: {
        height: {
          "ui:placeholder": `5' 9"`,
        },
      },
      patientDiagnosis: {
        "ui:widget": "radio",
      },
    },
    patternErrorMessages: {
      ".patientInformation.height": `Please enter the height as "[feet]' [inches]"" (IE: 5' 9")`,
    },
    validate: (formData, errors) => {
      if (new Date(formData.infantDOB) > new Date()) {
        errors.infantDOB.addError("Infants Date of Birth mut be in the past.");
      }
      return errors;
    },
  },
  blood_pressure_monitor: {
    id: "blood_pressure_monitor",
    name: "Blood Pressure Monitor",
    orderSchema: {
      type: "object",
      properties: {
        lastBloodPressure: {
          type: "object",
          title: "Last blood pressure",
          properties: {
            date: {
              title: "Date & Time Taken",
              type: "string",
              format: "date-time",
            },
            systolic: { type: "number", title: "Systolic blood pressure" },
            diastolic: { type: "number", title: "Diastolic blood pressure" },
          },
        },
      },
    },
  },
};
