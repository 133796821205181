import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { products } from "../Product/productData";

export const Home: FunctionComponent = () => {
  const items = Object.values(products);
  return (
    <div>
      <h1>Welcome to my demo</h1>
      <p>We have {items.length} items available:</p>
      <ul>
        {items.map(({ id, name }) => (
          <li key={id}>
            <Link to={`/products/${id}`}>{name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
