import Form from "@rjsf/core";
import { FunctionComponent, useState } from "react";
import { useParams } from "react-router";
import { products } from "./productData";

import styles from "./product.module.css";

export const Product: FunctionComponent = () => {
  const { productid } = useParams<{ productid: string }>();
  const product = products[productid];
  const [formData, setFormData] = useState({});

  if (!product) {
    return <h1>No such product found</h1>;
  }

  const { orderSchema, uiSchema, patternErrorMessages, validate } = product;

  return (
    <div className={styles.product}>
      <h1>{product.name}</h1>
      <Form
        schema={orderSchema}
        uiSchema={uiSchema}
        formData={formData}
        onChange={(evt) => setFormData(evt.formData)}
        liveValidate
        transformErrors={(errors) =>
          errors.map((error) => {
            if (
              error.name === "pattern" &&
              patternErrorMessages?.[error.property]
            ) {
              error.message = patternErrorMessages[error.property];
            }
            return error;
          })
        }
        validate={validate}
      />
      <pre>{JSON.stringify(formData, null, 4)}</pre>
    </div>
  );
};
